import React from 'react'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

const SEO = () => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            title
            description
            favicon
          }
        }
      }
    `}
    render={({
      site: {
        siteMetadata: { title, description, favicon },
      },
    }) => (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        {favicon ? <link rel="shortcut icon" href={favicon} /> : null}
      </Helmet>
    )}
  />
)

export default SEO
