/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import React from 'react'
import { ThemeProvider } from 'emotion-theming'
import Components from '../Loader'
import Layout from '../components/Layout'

const theme = {
  "id": 12,
  "colors": {
    "front1": "rgba(29,38,45,1)",
    "front2": "rgba(255,255,255,1)",
    "front3": "rgba(210,152,119,1)",
    "front4": "rgba(124,124,124,1)",
    "back1": "rgba(255,255,255,1)",
    "back2": "rgba(29,38,45,1)",
    "back3": "rgba(210,152,119,1)",
    "back4": "rgba(178,178,178,1)"
  },
  "variables": {
    "color-variable-1": "rgba(36, 39, 46, 0.85)",
    "color-variable-2": "rgba(29, 38, 45, 1)",
    "color-variable-btn-1": "rgba(255, 255, 255, 1)",
    "color-variable-btn-2": "rgba(0, 0, 0, 1)"
  },
  "globalMobile": "27em",
  "meta": []
}

export default function Page(props) {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Components.Column className="--menu --style3 pb--02 pt--02" menu={true} name={"bnj1lnmzyr8"} style={{"paddingTop":null,"paddingBottom":null,"backgroundColor":"rgba(19, 19, 22, 0.92)"}} styleFlag={3}>
          
          <Components.Menu className="--full --left pb--02 pt--02" style={{"paddingTop":null,"paddingBottom":null,"backgroundColor":"rgba(34, 31, 41, 0.96)"}}>
            
            <Components.ColumnWrapper className="menu-logo-box --left">
              
              <Components.Subtitle className="subtitle-box subtitle-box--style1 subtitle-box--left ff--2 fs--20 w--900" content={"<span style=\"color: rgb(255, 255, 255);\">NeW Way <span style=\"font-weight: normal;\">CONCEPT</span></span>"} styleFlag={1}>
              </Components.Subtitle>

            </Components.ColumnWrapper>

            <Components.MenuWrap >
              
              <Components.MenuButton >
              </Components.MenuButton>

              <Components.MenuWrapBox className="--style2 --right">
                
                <Components.MenuButtonClose >
                </Components.MenuButtonClose>

                <Components.Button className="btn-box btn-box--style5 fs--14 w--300 ls--01" href={"#services"} style={{"style":5}} target={""} content={"<span style=\"color: rgb(255, 255, 255);\">SERVICES</span><br>"} styleFlag={5}>
                </Components.Button>

                <Components.Button className="btn-box btn-box--style5 fs--14 w--300 ls--0" href={"#modelsandhostesses"} style={{"style":5}} target={""} content={"<span style=\"color: rgb(255, 255, 255);\">MODELS &amp; HOSTESSES</span><br>"} styleFlag={5}>
                </Components.Button>

                <Components.Button className="btn-box btn-box--style5 fs--14 w--300 ls--01" href={"#events"} style={{"style":5}} target={""} content={"<span style=\"color: rgb(255, 255, 255);\">EVENTS</span><br>"} styleFlag={5}>
                </Components.Button>

                <Components.Button className="btn-box btn-box--style5 fs--14 w--300 ls--01" href={"#wedding"} style={{"style":5}} target={""} content={"<span style=\"color: rgb(255, 255, 255);\">WEDDING</span><br>"} styleFlag={5}>
                </Components.Button>

                <Components.Button className="btn-box btn-box--style5 fs--14 w--300 ls--002" href={"#clothes-design"} style={{"style":5}} target={""} content={"<span style=\"color: rgb(255, 255, 255);\">CLOTHES DESIGN</span><br>"} styleFlag={5}>
                </Components.Button>

                <Components.Button className="btn-box btn-box--style5 fs--18 w--600 ls--02" href={"#contact"} style={{"style":5}} target={""} content={"<span style=\"color: rgb(255, 255, 255);\">CONTACT</span><br>"} styleFlag={5}>
                </Components.Button>

              </Components.MenuWrapBox>

            </Components.MenuWrap>

          </Components.Menu>

        </Components.Column>


        <Components.Column className="--style2 bg--top --full" name={"uvod"} fullscreen={true} css={css`
          @media (min-width: 1401px) {
            background-image: url(https://cdn.swbpg.com/t/3280/9362632c0d724a56b1888602977b1c3b_s=2000x_.jpeg);
          }
          @media (max-width: 1400px) {
            background-image: url(https://cdn.swbpg.com/t/3280/9362632c0d724a56b1888602977b1c3b_s=1400x_.jpeg);
          }
        `} styleFlag={2}>
          
          <Components.ColumnWrap className="column__flex --full --right el--1" columns={"1"}>
            
            <Components.ColumnWrapper className="--right" style={{"maxWidth":470}}>
              
              <Components.Subtitle className="subtitle-box subtitle-box--style4 subtitle-box--center ff--2 fs--30 w--300 subtitle-box--invert ls--08 lh--16" style={{"color":"var(--color-variable-1)","maxWidth":411,"marginTop":0,"paddingLeft":null,"marginBottom":26}} content={"<span style=\"font-style: italic; color: rgb(255, 255, 255);\">Wishes start your way..</span>"} styleFlag={4}>
              </Components.Subtitle>

              <Components.Title className="title-box title-box--style1 title-box--center ff--2 fs--48 w--500 ls--08 mt--02" content={"<a href=\"#panske-strihy\"><span style=\"font-weight: bold;\">NeW Way</span> CONCEPT</a>"} styleFlag={1}>
              </Components.Title>

              <Components.Text className="text-box pr--10" style={{"maxWidth":330,"marginBottom":73,"paddingRight":null}} content={"We are happy to make your wish come true!<br>&nbsp;Years of experience, individual approach to each client, professionalism and passion for detail is our key to together success.<br><br>"}>
              </Components.Text>

              <Components.Button className="btn-box btn-box--style1 btn-box--right fs--18 w--600 ls--20 lh--1 pr--10" href={"#services"} style={{"maxWidth":290,"marginTop":23.5,"paddingLeft":null,"paddingRight":null,"backgroundColor":"rgba(36, 39, 46, 0.85)"}} content={"<span style=\"color: rgb(255, 255, 255);\">SERVICES</span><br>"} styleFlag={1}>
              </Components.Button>

            </Components.ColumnWrapper>

          </Components.ColumnWrap>

        </Components.Column>


        <Components.Column className="pb--50 pt--50" name={"services"} layout={"l2"}>
          
          <Components.ColumnWrap className="column__flex --full el--4" columns={"4"}>
            
            <Components.ColumnWrapper className="--center" style={{"paddingTop":5,"marginBottom":0}}>
              
              <Components.Image className="--style2 mb--12 mt--0 pl--20" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/spa/802be2a28bcc407dbb95b01ded4e585d.svg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":100,"paddingLeft":null}} srcSet={""} content={null} RootClassName={"column__icon"} styleFlag={2}>
              </Components.Image>

              <Components.Subtitle className="subtitle-box subtitle-box--style4 subtitle-box--center ff--2 fs--24 w--600 subtitle-box--invert pl--10 pr--0" style={{"maxWidth":328,"paddingLeft":null,"paddingRight":null}} content={"<span style=\"color: rgb(34, 26, 76);\">MODELS &amp;<br>&nbsp;HOSTESSES</span>"} styleFlag={4}>
              </Components.Subtitle>

              <Components.Title className="title-box title-box--style1 fs--18 pb--10 pl--20 pt--02" style={{"paddingTop":null,"paddingLeft":null,"paddingBottom":null}} content={"<span style=\"color: rgb(53, 51, 63);\">Motorsport, fashion, fair trades</span><br>"} styleFlag={1}>
              </Components.Title>

              <Components.Text className="text-box pl--20" style={{"maxWidth":300,"paddingLeft":null}} content={"NewWay Concept offers the best services in area of grid girls, fashion and lifestyle models but as well beautiful and &nbsp;linguistically equipped hostesses with huge experience. Let us rise your level of the event across the Europe!&nbsp;"}>
              </Components.Text>

            </Components.ColumnWrapper>

            <Components.ColumnWrapper className="--center">
              
              <Components.Image className="--style2 mb--12" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Hotel/hotel-31.svg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":100}} srcSet={""} content={null} RootClassName={"column__icon"} styleFlag={2}>
              </Components.Image>

              <Components.Subtitle className="subtitle-box subtitle-box--style4 subtitle-box--center ff--2 fs--24 w--600 subtitle-box--invert ls--08 pb--06 pt--06" style={{"paddingTop":null,"paddingBottom":null}} content={"<span style=\"color: rgb(34, 25, 77);\">EVENTS</span>"} styleFlag={4}>
              </Components.Subtitle>

              <Components.Title className="title-box fs--18 w--600 pb--08 pt--06" style={{"paddingTop":null,"paddingBottom":null}} content={"<span style=\"color: rgb(52, 50, 62);\">Sport events, teambuildings, corporate events</span><br>"}>
              </Components.Title>

              <Components.Text className="text-box" style={{"maxWidth":300}} content={"We help you to create event which will everyone remember! Doesn't matter if it's sport event or corporate event, we always take care about your wishes and focus on every single detail.<br>"}>
              </Components.Text>

            </Components.ColumnWrapper>

            <Components.ColumnWrapper className="--center">
              
              <Components.Image className="--style2 mb--12" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Kadernictvi/kadernictvi-3.svg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":100}} srcSet={""} content={null} RootClassName={"column__icon"} styleFlag={2}>
              </Components.Image>

              <Components.Subtitle className="subtitle-box subtitle-box--style4 subtitle-box--center ff--2 fs--24 w--600 subtitle-box--invert pb--08 pt--06" style={{"paddingTop":null,"paddingBottom":null}} content={"<span style=\"color: rgb(36, 27, 83);\">WEDDING</span>"} styleFlag={4}>
              </Components.Subtitle>

              <Components.Title className="title-box fs--18 w--600 pt--06" style={{"paddingTop":null}} content={"<span style=\"color: rgb(47, 45, 58);\">Decorations, organisation, coordinating</span><br>"}>
              </Components.Title>

              <Components.Text className="text-box" style={{"maxWidth":300}} content={"Wedding and home decoration with fresh flowers is one of our biggest passion! And even more when we can decorate for a wedding day of people which love each other. That's why we like to organise the whole wedding day and coordinate it while they enjoy it all!"}>
              </Components.Text>

            </Components.ColumnWrapper>

            <Components.ColumnWrapper className="--center">
              
              <Components.Image className="--style2 mb--12 pr--20" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Finance/finanfce-4.svg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":100,"paddingRight":null}} srcSet={""} content={null} RootClassName={"column__icon"} styleFlag={2}>
              </Components.Image>

              <Components.Subtitle className="subtitle-box subtitle-box--style4 subtitle-box--center ff--2 fs--24 w--600 subtitle-box--invert pb--0 pr--20 pt--0" style={{"paddingTop":null,"paddingRight":null,"paddingBottom":null}} content={"<span style=\"caret-color: rgb(36, 27, 83); color: rgb(36, 27, 83);\">CLOTHES DESIGN</span><br>"} styleFlag={4}>
              </Components.Subtitle>

              <Components.Title className="title-box fs--18 w--600 pr--20 pt--06" style={{"paddingTop":null,"paddingRight":null}} content={"<span style=\"caret-color: rgb(29, 67, 150); color: rgb(53, 51, 67);\">Team &amp; company outfits, evening dress, wedding dress</span><br>"}>
              </Components.Title>

              <Components.Text className="text-box pr--20" style={{"maxWidth":300,"paddingRight":null}} content={"Passion for creativity, design and beauty in every piece of clothes made us to do it! We'll be glad to design and create occasional outfits for your special moment or for your company and team event!&nbsp;"}>
              </Components.Text>

            </Components.ColumnWrapper>

          </Components.ColumnWrap>

        </Components.Column>


        <Components.Column className="--right bg--center mb--30 mt--30 pb--0 pt--0" name={"brazilsky-keratin"} style={{"backgroundColor":"var(--color-variable-2)"}} layout={"l13"}>
        </Components.Column>


        <Components.Column className="--left pb--0 pt--0" name={"modelsandhostesses"} style={{"paddingTop":null,"paddingBottom":null,"backgroundColor":"rgba(40, 38, 48, 1)"}} layout={"l13"}>
          
          <Components.ColumnWrap className="column__flex --full el--2 flex--center" columns={"2"}>
            
            <Components.ColumnWrapper className="--center">
              
              <Components.Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/3280/581fb43c114c4875b385feeca1687ea2_s=660x_.png"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/3280/581fb43c114c4875b385feeca1687ea2_s=350x_.png 350w, https://cdn.swbpg.com/t/3280/581fb43c114c4875b385feeca1687ea2_s=660x_.png 660w, https://cdn.swbpg.com/t/3280/581fb43c114c4875b385feeca1687ea2_s=860x_.png 860w"} content={null} RootClassName={"column__pic"}>
              </Components.Image>

            </Components.ColumnWrapper>

            <Components.ColumnWrapper className="--center pb--20 pt--0" style={{"paddingTop":null,"paddingBottom":null}}>
              
              <Components.Title className="title-box title-box--right ff--3 fs--20 title-box--invert pb--06 pr--20 pt--20" style={{"paddingTop":null,"paddingRight":null,"paddingBottom":null}} content={"Last step for making your event perfect and success...<br>"}>
              </Components.Title>

              <Components.Title className="title-box title-box--style4 title-box--right w--600 title-box--invert ls--08 pr--20" style={{"paddingRight":null}} content={"<span style=\"color: rgb(255, 255, 255);\">MODELS &amp; HOSTESSES<br></span>"} styleFlag={4}>
              </Components.Title>

              <Components.Text className="text-box text-box--right text-box--invert pr--20" style={{"paddingRight":null}} content={"After years of experience of modelling, especially &nbsp;in motorsport all around the world, we became professionals in area of grid girls at MotoGP, WSBK and GT Masters.<br>&nbsp;However we focus also to fashion shows, photoshoots and videoshoots and we are sure our lifestyle models and hostesses create your event unforgottable.<br>"}>
              </Components.Text>

              <Components.Text className="text-box text-box--right text-box--invert pr--20" style={{"paddingRight":null}} content={"- grid girls / umbrella girls worldwide<br>- lifestyle models<br>- fashion shows<br>- commercial photoshoots<br>- commercial videoshoots<br>- exhibitions, trade shows and showrooms<br>- conferences<br>- gala events<br>- social evetns<br>"}>
              </Components.Text>

              <Components.Button className="btn-box btn-box--style1 btn-box--right fs--14 w--600 pr--10" href={"#contact"} style={{"maxWidth":477,"paddingTop":0,"paddingRight":null,"backgroundColor":"rgba(255, 255, 255, 0.97)"}} content={"<span style=\"color: rgb(26, 18, 67);\">SEND AN INQUIRE</span>"} styleFlag={1}>
              </Components.Button>

            </Components.ColumnWrapper>

          </Components.ColumnWrap>

        </Components.Column>


        <Components.Column className="bg--center pb--0 pt--0" name={"events"} style={{"paddingTop":null,"paddingBottom":null}} layout={"l17"}>
          
          <Components.ColumnWrap className="column__flex --full --right el--2 pb--0 pt--0 flex--center" style={{"maxWidth":1240,"paddingTop":null,"paddingBottom":null}} columns={"2"}>
            
            <Components.ColumnWrapper className="--left">
              
              <Components.Title className="title-box title-box--style1 ff--3 fs--18 w--600 ls--04 lh--2 pb--06 pl--20" style={{"paddingLeft":null,"paddingBottom":null}} content={"<br>Every detail matters...<br>"} styleFlag={1}>
              </Components.Title>

              <Components.Title className="title-box title-box--style4 pl--20" style={{"paddingLeft":null}} content={"EVENTS"} styleFlag={4}>
              </Components.Title>

              <Components.Text className="text-box pl--20" style={{"paddingLeft":null}} content={"It's not easy but we love to do it always on 120%. New Way Concept helps you with sport events, company events, team buildings, fair trades, social events and other kind of events. Tell us, how your dream event should look like and we organise every single detail for your and your clients satisfaction. We don't say \"NO\", we're are always looking for new solutions!<br><br>- sport events<br>- team buildings<br>- company events<br>- fair trades<br>- fair trades and exhibitions<br>- baby showers, parties<br>"}>
              </Components.Text>

              <Components.Button className="btn-box fs--13 w--600 pl--10" href={"#contact"} style={{"paddingLeft":null,"backgroundColor":"rgba(30, 32, 40, 0.93)"}} content={"<span style=\"color: rgb(255, 255, 255);\">SEND AN INQUIRE</span>"}>
              </Components.Button>

            </Components.ColumnWrapper>

            <Components.ColumnWrapper className="--center">
              
              <Components.Image className="pt--20" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/3280/9ca6ebd4b9f24200a1e4f0c12dd81e4f_e=4x5x1817x1412_s=860x_.jpeg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":690,"paddingTop":null,"marginBottom":36}} srcSet={"https://cdn.swbpg.com/t/3280/9ca6ebd4b9f24200a1e4f0c12dd81e4f_e=4x5x1817x1412_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/3280/9ca6ebd4b9f24200a1e4f0c12dd81e4f_e=4x5x1817x1412_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/3280/9ca6ebd4b9f24200a1e4f0c12dd81e4f_e=4x5x1817x1412_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/3280/9ca6ebd4b9f24200a1e4f0c12dd81e4f_e=4x5x1817x1412_s=1400x_.jpeg 1400w"} content={null} RootClassName={"column__pic"}>
              </Components.Image>

            </Components.ColumnWrapper>

          </Components.ColumnWrap>

        </Components.Column>


        <Components.Column className="--left bg--center pb--0 pt--0" name={"wedding"} style={{"paddingTop":null,"paddingBottom":null,"backgroundColor":"rgba(40, 38, 48, 1)"}} layout={"l13"}>
          
          <Components.ColumnWrap className="column__flex --full el--2 pb--0" style={{"paddingBottom":null,"backgroundColor":"rgba(39, 39, 52, 0.97)"}} columns={"2"}>
            
            <Components.ColumnWrapper className="--center">
              
              <Components.Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/3280/85c1e18ffab642c19005e404246b386b_s=860x_.jpeg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":780}} srcSet={"https://cdn.swbpg.com/t/3280/85c1e18ffab642c19005e404246b386b_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/3280/85c1e18ffab642c19005e404246b386b_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/3280/85c1e18ffab642c19005e404246b386b_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/3280/85c1e18ffab642c19005e404246b386b_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/3280/85c1e18ffab642c19005e404246b386b_s=2000x_.jpeg 2000w"} content={null} RootClassName={"column__pic"}>
              </Components.Image>

            </Components.ColumnWrapper>

            <Components.ColumnWrapper className="--center pb--16" style={{"paddingBottom":null}}>
              
              <Components.Title className="title-box title-box--right ff--3 fs--20 pb--06 pr--20 pt--06" style={{"paddingTop":null,"paddingRight":null,"paddingBottom":null}} content={"<span style=\"caret-color: rgb(255, 255, 255); color: rgb(255, 255, 255);\">Let's the new era of life begin...</span><br>"}>
              </Components.Title>

              <Components.Title className="title-box title-box--style4 title-box--right w--600 title-box--invert ls--08 pr--20" style={{"paddingRight":null,"paddingBottom":null}} content={"<span style=\"color: rgb(255, 255, 255);\">WEDDING<br></span>"} styleFlag={4}>
              </Components.Title>

              <Components.Text className="text-box text-box--right fs--14 w--300 text-box--invert pr--20" style={{"paddingRight":null}} content={"<span style=\"caret-color: rgb(255, 255, 255); color: rgb(255, 255, 255);\">There is only one day in our life which matters the most - Wedding day. We know how important it is for you and for your family and that's the reason why we are here - to help you with whole wedding planning, organisation, preparation, decoration of the wedding place &nbsp;and also to coordinate your wedding meanwhile you're enjoying the most beautiful time of your love life.&nbsp;<br></span><br>- wedding planning<br>- wedding organisation<br>- &nbsp;wedding decorations &amp; materials<br>- wedding preparations<br>- makeup and hair artists<br>- photo and video production<br>- coordination of the wedding<br>"}>
              </Components.Text>

              <Components.Button className="btn-box btn-box--style1 btn-box--right fs--14 w--600 pr--10" href={"#contact"} style={{"paddingRight":null,"backgroundColor":"rgba(255, 255, 255, 0.97)"}} content={"<span style=\"color: rgb(26, 18, 67);\">SEND AN INQUIRE</span>"} styleFlag={1}>
              </Components.Button>

            </Components.ColumnWrapper>

          </Components.ColumnWrap>

        </Components.Column>


        <Components.Column className="bg--center pb--0 pt--0" name={"clothes-design"} style={{"paddingTop":null,"paddingBottom":null}} layout={"l17"}>
          
          <Components.ColumnWrap className="column__flex --full --right el--2 pb--10" style={{"paddingTop":null,"paddingBottom":null}} columns={"2"}>
            
            <Components.ColumnWrapper className="--left">
              
              <Components.Title className="title-box title-box--style1 ff--3 fs--18 w--600 ls--04 lh--2 pb--06 pl--20 pt--10" style={{"paddingTop":null,"paddingLeft":null,"paddingBottom":null}} content={"<br>Anything is impossible for us...<br>"} styleFlag={1}>
              </Components.Title>

              <Components.Title className="title-box title-box--style4 title-box--left ls--04 pl--20" style={{"paddingLeft":null}} content={"CLOTHES DESIGN<br>"} styleFlag={4}>
              </Components.Title>

              <Components.Text className="text-box pl--20" style={{"paddingLeft":null}} content={"Wearing nice clothes and feel pretty in every occasion should be a priority of each person. We love to be creative and make you feel good, beautiful and comfortable in the same time! No matter if you need clothes for a sport events, business occasions, dress for your wedding or for a special night out. We design, sew and prepare the dress of your dreams. Tell us what is your idea and we make it together in style!<br><br>- design &amp; sewing<br>- sport clothes and dress<br>- promotion clothes<br>- business outfits<br>- wedding dress<br>- evening dress<br>"}>
              </Components.Text>

              <Components.Button className="btn-box fs--13 w--600 pl--10" href={"#contact"} style={{"paddingLeft":null,"backgroundColor":"rgba(24, 24, 30, 0.88)"}} content={"<span style=\"color: rgb(255, 255, 255);\">SEND AN INQUIRE</span>"}>
              </Components.Button>

            </Components.ColumnWrapper>

            <Components.ColumnWrapper className="--style1 --center" styleFlag={1}>
              
              <Components.Image className="--center" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/3280/4d8849e5de00436da7cd63e297b550dc_e=65x2x1813x1331_s=860x_.jpeg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":690,"marginBottom":39}} srcSet={"https://cdn.swbpg.com/t/3280/4d8849e5de00436da7cd63e297b550dc_e=65x2x1813x1331_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/3280/4d8849e5de00436da7cd63e297b550dc_e=65x2x1813x1331_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/3280/4d8849e5de00436da7cd63e297b550dc_e=65x2x1813x1331_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/3280/4d8849e5de00436da7cd63e297b550dc_e=65x2x1813x1331_s=1400x_.jpeg 1400w"} content={null} RootClassName={"column__pic"}>
              </Components.Image>

            </Components.ColumnWrapper>

          </Components.ColumnWrap>

        </Components.Column>


        <Components.Column className="bg--center" name={"panske-strihy"} style={{"paddingTop":null,"paddingBottom":null}} layout={"l1"} css={css`
          @media (min-width: 1401px) {
            background-image: url(https://cdn.swbpg.com/t/3280/454bf47a300446659d67dfa31e041225_s=2000x_.jpg);
          }
          @media (max-width: 1400px) {
            background-image: url(https://cdn.swbpg.com/t/3280/454bf47a300446659d67dfa31e041225_s=1400x_.jpg);
          }
        `}>
        </Components.Column>


        <Components.Column className="contact--l6" name={"contact"} style={{"backgroundColor":"var(--color-main-bg-1)"}} layout={"l5"}>
          
          <Components.ColumnWrap className="column__flex --full el--3 flex--stretch" columns={"3"}>
            
            <Components.ColumnWrapper className="--left pb--0 pl--08 pr--06 pt--30" style={{"paddingTop":null,"paddingLeft":null,"paddingRight":null,"paddingBottom":null,"backgroundColor":"rgba(17, 17, 27, 0.89)"}}>
              
              <Components.Text className="text-box ff--2 fs--20 w--500 pl--20" style={{"paddingLeft":null}} content={"<span style=\"color: rgb(255, 255, 255);\">CONTACT US<br></span>"}>
              </Components.Text>

              <Components.Text className="text-box ff--2 fs--16 w--300 pb--08 pl--20 pr--06 pt--02" style={{"paddingTop":null,"paddingLeft":null,"paddingRight":null,"paddingBottom":null}} content={"<span style=\"color: rgb(255, 255, 255);\">Are you interested in our services? Or do you just have a question? Then do not hesitate to contact us! Please use the contact form and we will get back to you as soon as possible. </span>"}>
              </Components.Text>

              <Components.ColumnHeaderWrap className="flex --left --invert pl--20" style={{"paddingLeft":null}}>
                
                <Components.Image className="--invert" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Kontakt/email-8.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={""} RootClassName={"column__icon"}>
                </Components.Image>

                <Components.ColumnContent >
                  
                  <Components.Subtitle className="subtitle-box ff--2 fs--16 subtitle-box--invert" content={"info@newwayconcept.com<br>"}>
                  </Components.Subtitle>

                </Components.ColumnContent>

              </Components.ColumnHeaderWrap>

              <Components.ColumnHeaderWrap className="flex --left">
                
                <Components.Image className="--invert pl--10" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Kontakt/telefon-2.svg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"paddingLeft":null}} srcSet={""} content={null} RootClassName={"column__icon"}>
                </Components.Image>

                <Components.ColumnContent >
                  
                  <Components.Subtitle className="subtitle-box ff--2 fs--16 subtitle-box--invert" content={"+420 775 356 903"}>
                  </Components.Subtitle>

                </Components.ColumnContent>

              </Components.ColumnHeaderWrap>

              <Components.ColumnHeaderWrap className="flex --left">
                
                <Components.Image className="--invert pl--10" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Adresa/adresa-7.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"paddingLeft":null}} srcSet={""} RootClassName={"column__icon"}>
                </Components.Image>

                <Components.ColumnContent >
                  
                  <Components.Subtitle className="subtitle-box ff--2 fs--16 w--300 subtitle-box--invert pt--16" content={"Brno<br>Czech republic<br>"}>
                  </Components.Subtitle>

                </Components.ColumnContent>

              </Components.ColumnHeaderWrap>

            </Components.ColumnWrapper>

            <Components.ColumnWrapper className="--style1 --left pb--30 pt--30" style={{"backgroundColor":"rgba(244, 244, 244, 1)"}} styleFlag={1}>
              
              <Components.Subtitle className="subtitle-box subtitle-box--center ff--2 subtitle-box--invert" content={"<br>"}>
              </Components.Subtitle>

              <Components.Form className="--left --invert" action={"/contact"} fields={[{"name":"NAME, SURNAME","type":"text","required":true,"placeholder":"ENTER YOUR NAME"},{"name":"E-MAIL","type":"email","required":true,"placeholder":"ENTER YOUR E-MAIL"},{"name":"PHONE NUMBER","type":"text","placeholder":"ENTER YOUR PHONE"},{"name":"MESSAGE","type":"textarea","required":true,"placeholder":"ENTER YOUR MESSAGE"},{"name":"SEND","type":"submit"}]} layout={"l6"}>
              </Components.Form>

            </Components.ColumnWrapper>

            <Components.ColumnWrapper className="--map">
              
              <Components.FullmapCover className="full-map__cover" place={"Brno"}>
              </Components.FullmapCover>

            </Components.ColumnWrapper>

          </Components.ColumnWrap>

        </Components.Column>

      </Layout>
    </ThemeProvider>
  )
}